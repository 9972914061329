<template>
    <div id="CompleteAllTask" class="h-100"> 
    <Header/>  
    <body class="h-100 bg-light cel-over" style="overflow: hidden;">
<!-- Button trigger modal -->   
<div class="position-relative  mx-auto ">
    <img class="d-md-none cel nicole-result-center goodtop" src="assets/img/celebrate-phone.png">    
    <img class="d-none d-md-block cel nicole-result-center goodtop" src="assets/img/celebrate-pad.png"> 
    
    <div class="nicole-result-center vh-50 goodtop goodword"> 
         <h4 class="text-center text-card-name fw-simbold text-dark mb-0">你真是太優秀了，</h4>
         <h4 class="text-center text-card-xl fw-bold text-dark my-4 my-md-5">英文能力又更強了！</h4>
         <h4 class="text-center text-card-name fw-simbold text-dark mb-0">要繼續保持戰鬥力喲！</h4>
        <div class="mt-cel text-center">
        <button type="button" class="btn py-2 mt-md-4 mt-xl-5 btn-success rounded-pill w-75" @click="onNext">挑戰下一篇</button>
        </div>
   </div>
</div>
</body>
    </div>
</template>


<script>
import Header from '@/components/Header.vue'

export default {
    name: 'CompleteAllTask',
    components: {
        Header,
    },
    data () {
    return { 
        }
    },
    methods: {
        saveTaskFirstCallback(data){
            this.$router.push(
            {
                name : 'TaskList',
                params : this.$route.params
            });
        },
        loadTaskFirstCallback(data){
            if(data != "" && data != "N"){
                try{
                    var json = JSON.parse(data);
                    json[`lsid_${this.$route.params.lsid}`] = "N";
                    var saveJson = {
                        key: "isTaskCompleteFirst",
                        object: JSON.stringify(json),
                    };
                    this.$JsBridge.callHandler('saveLocalDataVue', JSON.stringify( saveJson ), this.saveTaskFirstCallback);
                }catch(err) {
                    var json = JSON.parse(decodeURIComponent(atob(data)));
                    json[`lsid_${this.$route.params.lsid}`] = "N";
                    var saveJson = {
                        key: "isTaskCompleteFirst",
                        object: JSON.stringify(json),
                    };
                    this.$JsBridge.callHandler('saveLocalDataVue', JSON.stringify( saveJson ), this.saveTaskFirstCallback);
                    console.log(err);
                }
            }else{
                    var json = {};
                    json[`lsid_${this.$route.params.lsid}`] = "N";
                    var saveJson = {
                        key: "isTaskCompleteFirst",
                        object: JSON.stringify(json),
                    };
                    this.$JsBridge.callHandler('saveLocalDataVue', JSON.stringify( saveJson ), this.saveTaskFirstCallback);
            }
        },
        onNext(){  
            this.$JsBridge.callHandler('loadLocalDataVue', JSON.stringify( {key: "isTaskCompleteFirst"}), this.loadTaskFirstCallback);  
        },
    },
    mounted() {
    },
    created(){   
    }
}
</script>